import { classNames } from 'primereact/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CodeBlock,
  SubTemplate,
  useCodeBlocksQuery,
  useImagesQuery,
  useLazyTemplateContentByParametersQuery,
  useLazyTemplatePreviewByContentIdParametersQuery,
  useLazyTemplatePreviewByContentParametersQuery,
  useLinksQuery,
  usePublishEntryPointMutation,
  useSubTemplatesQuery,
  useTemplateByIdQuery,
  useUpdateEntryPointContentMutation,
  Link,
  Image,
  useLazyEntryPointStatisticsDetailQuery,
  ParameterInput,
  KeyValuePairOfInt32AndString,
  Parameter,
} from '../../api/generated';
import { ActionType } from '../../common/actionTypes';
import { BooleanAsString } from '../../common/booleanAsString';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import useToast from '../../hooks/useToast';
import Editor from '../Editor';
import { ContentEditorProps, EntryPointEditorStore, MessageType } from '../Editor/editorTypes';
import { AppRoute } from '../../route/AppRoute';
import FaLocationDotIcon from '../Icons/FaLocationDotIcon';
import { Button } from 'primereact/button';
import TopBar from '../Editor/components/TopBar';
import { getDecisionParams, getSystemParams } from '../Editor/editorUtils';
import { RequestError } from '../../api/graphqlBaseQueryTypes';
import ContentEditorSection from '../Editor/components/sections/ContentEditorSection';
import ContentEditorEdit from './components/ContentEditorEdit';
import ContentEditorPreview from './components/ContentEditorPreview';
import { JoditEditorConnector, useJoditEditorConnector } from '../Editor/components/jodit-editor/JoditEditorConnector';
import { useShowStatistics } from '../../pages/EntryPoints/useShowStatistics';
import useValidateObjectInsert from '../../hooks/useValidateObjectInsert';
import EditorSidebar from './components/EditorSidebar';
import ParameterSampleValues from '../Editor/components/parameterSampleValues/parameterSampleValues';
import { ShowAdvanced } from '../ShowAdvanced';
import { useAdvanced } from './useAdvanced';
import { NestedParameterSection } from '../Editor/components/NestedParametersSection';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import FaMatrixIcon from '../Icons/FaMatrixIcon';
import { MatrixPreview } from './components/MatrixPreview/MatrixPreview';
import { useMatrixPreview } from './components/MatrixPreview/useMatrixPreview';
import { AvailableObjectType } from '../Editor/components/tree-items/AvailableObjectType';

export default function EntryPointEditor(props: ContentEditorProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const initializedRef = useRef(false);
  const toast = useToast();
  const [showStatistics] = useShowStatistics();
  const [advanced, setAdvanced] = useAdvanced();

  const [action, setAction] = useState<ActionType>(ActionType.Edit);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showSaveSuccessful, setShowSaveSuccessful] = useState(false);

  const { data: entryPointTemplateData, isLoading: isLoadingEntryPointTemplate } = useTemplateByIdQuery({
    id: props.currentTemplateId?.toString() || '',
  });

  const { data: codeBlocks, isLoading: isLoadingCodeBlocks } = useCodeBlocksQuery({});
  const { data: subTemplates, isLoading: isLoadingSubTemplates } = useSubTemplatesQuery({});
  const { data: images, isLoading: isLoadingImages } = useImagesQuery({});
  const { data: links, isLoading: isLoadingLinks } = useLinksQuery({});
  const [getTemplate, { isFetching: isLoadingTemplate }] = useLazyTemplateContentByParametersQuery({});
  const [getEntryPointStatisticsDetail] = useLazyEntryPointStatisticsDetailQuery({});

  const [updateContent, { isLoading: isSaving }] = useUpdateEntryPointContentMutation();
  const [publishContent, { isLoading: isPublishing }] = usePublishEntryPointMutation();
  const [contentId, setContentId] = useState<number | null>(null);

  const [customValueParameterInputs, setCustomValueParameterInputs] = useState<ParameterInput[]>([]);
  const [nestedDecisionParameterInputs, setNestedDecisionParameterInputs] = useState<ParameterInput[] | undefined>(
    undefined,
  );

  const lastFocusedEditorConnector = useRef<JoditEditorConnector | null>(null);
  const subjectEditorConnector = useJoditEditorConnector({ onChange: () => handleSubjectChange() });
  const bodyEditorConnector = useJoditEditorConnector({ onChange: () => handleBodyChange() });

  // Saved preview properties
  const [getSavedPreview, { isFetching: isLoadingSavedPreview, isError: hasSavedPreviewError }] =
    useLazyTemplatePreviewByContentIdParametersQuery({});
  const [savedPreviewError, setSavedPreviewError] = useState<string | undefined>();
  const [savedSubjectPreview, setSavedSubjectPreview] = useState<string | undefined>(undefined);
  const [savedBodyPreview, setSavedBodyPreview] = useState<string | undefined>(undefined);

  // Draft preview properties
  const [getDraftPreview, { isFetching: isLoadingDraftPreview, isError: hasDraftPreviewError }] =
    useLazyTemplatePreviewByContentParametersQuery({});
  const [draftPreviewError, setDraftPreviewError] = useState<string | undefined>();
  const [draftSubjectPreview, setDraftSubjectPreview] = useState('');
  const [draftBodyPreview, setDraftBodyPreview] = useState('');

  const [usedObjects, setUsedObjects] = useState<string[]>([]);

  const validateObjectInsert = useValidateObjectInsert();

  const messageTypeParameter = useMemo(() => {
    return props.contentParameters.find((p) => p?.parameter?.name === 'SysMessageType')?.parameter as Parameter;
  }, [props.contentParameters]);

  const [store, setStore] = useState<EntryPointEditorStore>({
    decisions: null,
    messageType: '',
    defaultLanguage: '',
    langsUsingDefault: [],
    specificLangs: [],
  });

  const hasEditorSelectedDeps = useMemo(() => {
    return !Array.from(store.decisions?.values() || []).some((value) => value === 'defaultValue');
  }, [store.decisions]);

  const decisionParamInputs: ParameterInput[] = useMemo(() => {
    return Array.from(store.decisions?.entries() || [])
      .filter(([_, value]) => value !== 'defaultValue')
      .map(([key, value]) => ({ parameterId: key, value }));
  }, [store.decisions]);

  const previewParamInputs: ParameterInput[] = useMemo(() => {
    return advanced
      ? decisionParamInputs.concat(customValueParameterInputs).concat(nestedDecisionParameterInputs || [])
      : decisionParamInputs;
  }, [advanced, decisionParamInputs, customValueParameterInputs, nestedDecisionParameterInputs]);

  const matrixPreviewData = useMatrixPreview(
    props.contentId,
    props.contentParameters,
    customValueParameterInputs,
    store.langsUsingDefault,
    action === ActionType.Matrix,
  );

  const tryReloadStatistics = useCallback(() => {
    if (showStatistics) {
      getEntryPointStatisticsDetail({
        id: props.contentId.toString(),
        input: { parameters: decisionParamInputs },
      });
    }
  }, [getEntryPointStatisticsDetail, decisionParamInputs, props.contentId, showStatistics]);

  const handleSave = useCallback(() => {
    updateContent({
      id: getContentIdOrThrow(contentId).toString(),
      input: {
        subject: subjectEditorConnector.getValue(),
        body: bodyEditorConnector.getValue(),
      },
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          setShowSaveSuccessful(false);
        }, 2000);
        setShowSaveSuccessful(true);
        setUnsavedChanges(false);
        tryReloadStatistics();
      })
      .catch(toast.error);
  }, [bodyEditorConnector, contentId, subjectEditorConnector, toast.error, tryReloadStatistics, updateContent]);

  const handlePublish = useCallback(async () => {
    const handlePublish = async (hideConfirmDialog?: () => void) => {
      hideConfirmDialog && hideConfirmDialog();
      await handleSave();
      await publishContent({ id: props.contentId.toString() })
        .unwrap()
        .then((result) => {
          result
            ? toast.success('Entry point has been published successfully.')
            : toast.error('Entry point has not been published.');
        })
        .catch(toast.error);
    };

    if (unsavedChanges) {
      const { hide } = confirmDialog({
        message: 'There are unsaved changes. Do you want save changes to continue?',
        header: 'Unsaved Changes',
        icon: 'fa-light fa-triangle-exclamation fa-2xl',
        accept: () => handlePublish(hide),
      });
    } else {
      handlePublish();
    }
  }, [handleSave, props.contentId, publishContent, toast, unsavedChanges]);

  const handlePreview = useCallback(
    async (contentId: number) => {
      if (advanced && !nestedDecisionParameterInputs) return;
      getSavedPreview({ input: { contentId: contentId, parameters: previewParamInputs } })
        .unwrap()
        .then((data) => {
          setSavedPreviewError(undefined);
          setSavedSubjectPreview(data?.templatePreviewByContentIdParameters?.subject || undefined);
          setSavedBodyPreview(data?.templatePreviewByContentIdParameters?.body || undefined);
        })
        .catch((e) => {
          const error = e as RequestError;
          setSavedPreviewError(error.errors.map((error) => error.message).join('. '));
          console.error(e);
        });

      if (unsavedChanges) {
        getDraftPreview({
          input: {
            subject: subjectEditorConnector.getValue(),
            body: bodyEditorConnector.getValue(),
            parameters: previewParamInputs,
          },
        })
          .unwrap()
          .then((data) => {
            setDraftPreviewError(undefined);
            setDraftSubjectPreview(data.templatePreviewByContentParameters?.subject || '');
            setDraftBodyPreview(data.templatePreviewByContentParameters?.body || '');
          })
          .catch((e) => {
            const error = e as RequestError;
            setDraftPreviewError(error.errors.map((error) => error.message).join('. '));
            console.error(e);
          });
      }
    },
    [
      advanced,
      nestedDecisionParameterInputs,
      getSavedPreview,
      previewParamInputs,
      unsavedChanges,
      getDraftPreview,
      subjectEditorConnector,
      bodyEditorConnector,
    ],
  );

  const handleUpdateStore = useCallback(
    (data: Partial<EntryPointEditorStore>, ask: boolean) => {
      const updateStore = (hideConfirmDialog?: () => void) => {
        hideConfirmDialog && hideConfirmDialog();
        setStore((prev) => ({ ...prev, ...data }));
        // console.log('Store updated:', data);
      };

      if (unsavedChanges && ask) {
        const { hide } = confirmDialog({
          message: 'You have unsaved changes. If you continue, your changes will be lost. Do you want to proceed?',
          header: 'Unsaved Changes',
          icon: 'fa-light fa-triangle-exclamation fa-2xl',
          accept: () => updateStore(hide),
        });
      } else {
        updateStore();
      }
    },
    [unsavedChanges, setStore],
  );

  const handleMatrixRedirect = useCallback(
    (parameters: KeyValuePairOfInt32AndString[]) => {
      const decisions = new Map<number, string>();
      parameters.forEach((param) => {
        decisions?.set(param.key, param.value);
        if (param.key === (messageTypeParameter.parameterId || 0)) {
          handleUpdateStore({ messageType: param.value }, false);
        }
      });
      handleUpdateStore({ decisions: decisions }, false);
      setAction(ActionType.Edit);
      contentRef.current && contentRef.current.scrollTo(0, 0);
    },
    [handleUpdateStore, messageTypeParameter.parameterId],
  );

  const getActiveEditorConnector = useCallback(() => {
    return lastFocusedEditorConnector.current || bodyEditorConnector;
  }, [bodyEditorConnector]);

  const detectUsedObjects = useCallback(() => {
    setUsedObjects(
      Array.from(new Set([...subjectEditorConnector.getVariables(), ...bodyEditorConnector.getVariables()])),
    );
  }, [bodyEditorConnector, subjectEditorConnector]);

  const handleSubjectChange = useCallback(() => {
    setUnsavedChanges(true);
    detectUsedObjects();
  }, [setUnsavedChanges, detectUsedObjects]);

  const handleBodyChange = useCallback(() => {
    setUnsavedChanges(true);
    detectUsedObjects();
  }, [setUnsavedChanges, detectUsedObjects]);

  /** Refetch data when decision params changes */
  const refetchTemplate = useCallback(async () => {
    if (entryPointTemplateData && entryPointTemplateData.templateById && decisionParamInputs.length > 0) {
      await getTemplate({
        input: {
          templateId: entryPointTemplateData.templateById.templateId,
          parameters: decisionParamInputs,
        },
      })
        .unwrap()
        .then((result) => {
          const templateContentId = result.templateContentByParameters?.contentId;

          setContentId(templateContentId || null);

          subjectEditorConnector.setValue(result.templateContentByParameters?.subject || '');
          bodyEditorConnector.setValue(result.templateContentByParameters?.body || '');
          setUnsavedChanges(false);
          detectUsedObjects();

          templateContentId && handlePreview(templateContentId);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryPointTemplateData, getTemplate, decisionParamInputs]);

  useEffect(() => {
    tryReloadStatistics();
  }, [decisionParamInputs, store.decisions, tryReloadStatistics]);

  /** Initialize store, refactored but still not great */
  useEffect(() => {
    if (!entryPointTemplateData || initializedRef.current) return;
    /** Parse language */
    const langsUsingDefault = entryPointTemplateData?.templateById?.languagesUsingDefaultLanguage || ([] as any);
    const defaultLanguage = entryPointTemplateData?.templateById?.defaultLanguage || '';
    let messageType = 'defaultValue';

    const decisions = new Map<number, string>();

    /** Parse decision params */
    props.contentParameters.forEach((p: any) => {
      if ((p && p.isDecision) || p?.parameter?.name.startsWith('Sys')) {
        if (showStatistics) {
          decisions.set(p.parameterId, 'defaultValue');
        } else {
          const firstItem = p.parameter?.enumItems
            ? p.parameter?.enumItems.filter((item: any) => item.enabled)[0].key
            : BooleanAsString.True;
          decisions.set(p.parameterId, firstItem);

          if (p?.parameter?.name === 'SysMessageType') {
            messageType = firstItem;
          }
        }
      }
    });

    if (!showStatistics) {
      /** Handle languages using default lang, select first available */
      const langs = props.contentParameters.find((p) => p?.parameter?.name === 'SysLanguage')?.parameter;
      const specificContentLangs =
        langs?.enumItems
          ?.filter((lang) => lang.key !== defaultLanguage)
          .filter((lang) => !langsUsingDefault.includes(lang.key))
          .map((entry) => entry.key) || [];

      if (specificContentLangs.length === 0 && defaultLanguage) {
        decisions.set(1, defaultLanguage);
      } else if (specificContentLangs.length > 0) {
        decisions.set(1, specificContentLangs[0]);
      }
    }

    handleUpdateStore({ decisions: decisions, messageType, langsUsingDefault, defaultLanguage }, false);
    initializedRef.current = true;
  }, [entryPointTemplateData, handleUpdateStore, props.contentParameters, showStatistics]);

  useEffect(() => {
    if (!initializedRef.current) return;
    refetchTemplate();
  }, [refetchTemplate, store.decisions]);

  /** Fetch template preview  */
  useEffect(() => {
    if (action === ActionType.View) {
      contentId && handlePreview(contentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, advanced, customValueParameterInputs, nestedDecisionParameterInputs]);

  const getContentIdOrThrow = (contentId: number | null | undefined) => {
    if (contentId) return contentId;
    throw new Error('Missing contentId');
  };

  const isLoading =
    props.isLoading ||
    isLoadingEntryPointTemplate ||
    isLoadingCodeBlocks ||
    isLoadingSubTemplates ||
    isLoadingImages ||
    isLoadingLinks;

  return (
    <>
      <Page
        className={classNames(props.className, 'content-editor', {
          'entry-point-preview': action === ActionType.View && unsavedChanges,
        })}
        windowTitle={`Edit ${props.content.name}`}
        topBar={
          <TopBar
            previousPath={AppRoute.EntryPoints}
            title={'Entry point'}
            icon={<FaLocationDotIcon />}
            contentName={props.content.name}
            previewVisible={true}
            isLoading={isLoading}
            setAction={setAction}
            actionButton={<Button onClick={handlePublish}>Publish</Button>}
            leftActions={<ShowAdvanced isAdvanced={advanced} onAdvancedChange={setAdvanced} />}
            customActions={[
              {
                label: 'Matrix Preview',
                icon: <FaMatrixIcon />,
                command: () => setAction(ActionType.Matrix),
              },
            ]}
            activeIndex={getActiveIndex(action)}
          />
        }
      >
        <MainLayoutWithSidebar
          contentRef={contentRef}
          sidebarPosition="left"
          isLoading={isLoading}
          sidebar={
            <>
              {(action === ActionType.View || action === ActionType.Matrix) && advanced ? (
                <ParameterSampleValues
                  templateId={props.currentTemplateId || 0}
                  parameters={props.contentParameters}
                  onParameterValueChanged={setCustomValueParameterInputs}
                  initialParameterInputs={customValueParameterInputs}
                />
              ) : (
                <EditorSidebar
                  contentParameters={props.contentParameters}
                  codeBlocks={codeBlocks?.allCodeBlocks as CodeBlock[]}
                  subTemplates={subTemplates?.allSubTemplates as SubTemplate[]}
                  images={images?.allImages as Image[]}
                  links={links?.allLinks as Link[]}
                  usedObjects={usedObjects}
                  onObjectSelect={(object) => {
                    getActiveEditorConnector().insertVariable(object.value);
                    validateObjectInsert(object, contentId || 0);
                  }}
                  path={props.path}
                  enabledTypes={
                    store.messageType !== MessageType.Email
                      ? [AvailableObjectType.CodeBlock, AvailableObjectType.Parameter, AvailableObjectType.SubTemplate]
                      : undefined
                  }
                />
              )}
            </>
          }
        >
          <ConfirmDialog />
          {action !== ActionType.Matrix && (
            <Editor
              path={props.path}
              action={action}
              decisionParams={getDecisionParams(props.contentParameters)}
              systemParams={getSystemParams(props.contentParameters)}
              store={store}
              onChange={(data) => handleUpdateStore(data, true)}
              onSave={handleSave}
              unsavedChanges={unsavedChanges}
              templateId={props.currentTemplateId?.toString() || ''}
            >
              {advanced && action === ActionType.View && (
                <NestedParameterSection
                  templateId={props.currentTemplateId || 0}
                  onValueChange={setNestedDecisionParameterInputs}
                />
              )}
              <div className="content-editor-section">
                <ContentEditorSection
                  action={action}
                  messageType={store.messageType as MessageType}
                  contentId={contentId || 0}
                  params={previewParamInputs}
                  unsavedChanges={unsavedChanges}
                  saveSuccessful={showSaveSuccessful}
                  isLoading={isLoadingTemplate}
                  isSaving={isSaving || isPublishing}
                  isDisabled={!hasEditorSelectedDeps}
                  minimalHeight={490}
                  onSave={handleSave}
                  onClear={() => {
                    subjectEditorConnector.setValue(null);
                    bodyEditorConnector.setValue(null);
                    setUnsavedChanges(true);
                  }}
                  edit={
                    <ContentEditorEdit
                      key={contentId}
                      messageType={store.messageType as MessageType}
                      subjectEditorConnector={subjectEditorConnector}
                      bodyEditorConnector={bodyEditorConnector}
                      onEditorFocus={(connector) => (lastFocusedEditorConnector.current = connector)}
                      isDisabled={!hasEditorSelectedDeps || isSaving || isPublishing || isLoadingTemplate}
                    />
                  }
                  preview={
                    <ContentEditorPreview
                      messageType={store.messageType}
                      savedSubjectPreview={savedSubjectPreview}
                      savedBodyPreview={savedBodyPreview}
                      draftSubjectPreview={draftSubjectPreview}
                      draftBodyPreview={draftBodyPreview}
                      isBodyEmpty={!savedBodyPreview}
                      unsavedChanges={unsavedChanges}
                      isLoadingSavedPreview={isLoadingSavedPreview}
                      isLoadingDraftPreview={isLoadingDraftPreview}
                      savedPreviewError={savedPreviewError}
                      draftPreviewError={draftPreviewError}
                      hasSavedPreviewError={hasSavedPreviewError}
                      hasDraftPreviewError={hasDraftPreviewError}
                    />
                  }
                />
              </div>
            </Editor>
          )}
          {action === ActionType.Matrix && (
            <MatrixPreview
              templateId={props.currentTemplateId || 0}
              matrixPreviewData={matrixPreviewData}
              onParameterCombinationClick={handleMatrixRedirect}
            />
          )}
        </MainLayoutWithSidebar>
      </Page>
    </>
  );
}

function getActiveIndex(action: ActionType) {
  switch (action) {
    case ActionType.Edit:
      return 0;
    case ActionType.View:
      return 1;
    case ActionType.Matrix:
      return 2;
    default:
      return 0;
  }
}
