import { Button } from 'primereact/button';
import { MenuItem } from 'primereact/menuitem';
import { ContextMenu } from 'primereact/contextmenu';
import { Column } from 'primereact/column';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Parameter, ParameterEnum, useDeleteParameterMutation, useParametersQuery } from '../../api/generated';
import { ActionType } from '../../common/actionTypes';
import { AppRoute, IAppPage } from '../../route/AppRoute';
import DataTable from '../../components/DataTable';
import DeleteDialog from '../../components/DeleteDialog';
import HeaderPanel from '../../components/HeaderPanel';
import MainLayoutWithSidebar from '../../components/MainLayoutWithSidebar';
import Page from '../../components/Page';
import { ParameterSidebar } from './components/ParameterSidebar';
import useToast from '../../hooks/useToast';
import ValueOrPlaceholder from '../../components/ValueOrPlaceholder';
import FaPlusIcon from '../../components/Icons/FaPlusIcon';
import FaBinaryIcon from '../../components/Icons/FaBinaryIcon';
import FaEditIcon from '../../components/Icons/FaEditIcon';
import FaTrashIcon from '../../components/Icons/FaTrashIcon';
import FaEllipsisVIcon from '../../components/Icons/FaEllipsisVIcon';

function Parameters() {
  const { data: parameters, isFetching: isLoading, refetch, error } = useParametersQuery();
  const [deleteParameter] = useDeleteParameterMutation();

  const [selectedParameterId, selectParameterId] = useState<number | undefined>();
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
  const [sysCategoriesMap, setSysCategoriesMap] = useState<{ [key: string]: string }>({});

  const [searchString, setSearchString] = useState('');
  const [action, setAction] = useState<ActionType>(ActionType.View);

  const onSearch = useCallback((searchVal: string) => setSearchString(searchVal), [setSearchString]);

  const cm = useRef<any>(null);
  const toast = useToast();

  useEffect(() => {
    const sysParam = parameters?.allParameters?.find((item) => item?.systemName === 'SysCategory');
    if (sysParam) {
      const map = sysParam.enumItems?.reduce((acc: { [key: string]: string }, item: ParameterEnum) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
      setSysCategoriesMap(map || {});
    }
  }, [parameters]);

  const menuModel: MenuItem[] = [
    {
      label: 'Properties',
      icon: <FaEditIcon />,
      command: (e) => {
        editParameter();
        e.originalEvent.stopPropagation();
      },
    },
    { separator: true },
    {
      label: 'Delete',
      icon: <FaTrashIcon />,
      className: 'danger',
      command: (e) => {
        setDeleteDialogVisible(true);
        e.originalEvent.stopPropagation();
      },
    },
  ];

  const selectParameter = useCallback((parameterId: number) => {
    selectParameterId(parameterId);
    setAction(ActionType.View);
    setSidebarVisible(true);
  }, []);

  const addParameter = useCallback(() => {
    setAction(ActionType.Add);
    setSidebarVisible(true);
  }, []);

  const editParameter = useCallback(() => {
    setAction(ActionType.Edit);
    setSidebarVisible(true);
  }, []);

  const tryToDeleteParameter = useCallback(
    async (parameterId: number) => {
      try {
        await deleteParameter({
          id: parameterId.toString() || '',
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Parameter '${
                parameters?.allParameters?.find((param) => param?.parameterId === selectedParameterId)?.name
              }' has been deleted successfully.`,
            );
            refetch();
            selectParameterId(undefined);
          })
          .catch(toast.error);
      } catch (ex) {
        toast.error(ex);
      }
    },
    [deleteParameter, parameters?.allParameters, refetch, selectedParameterId, toast],
  );

  const rowClass = (data: Parameter) => {
    return {
      selected: data.parameterId === selectedParameterId,
    };
  };

  const filteredParameters = useMemo(() => {
    if (searchString) {
      return parameters?.allParameters?.filter((param) =>
        param?.name.toLowerCase().includes(searchString.toLowerCase()),
      );
    }
    return parameters?.allParameters;
  }, [parameters?.allParameters, searchString]);

  return (
    <Page className="page-parameters" windowTitle={parametersPage.title} error={error}>
      <MainLayoutWithSidebar
        sidebar={
          <ParameterSidebar
            parameterId={selectedParameterId}
            sysCategoriesMap={sysCategoriesMap}
            sidebarVisible={sidebarVisible}
            onSidebarClose={() => {
              setAction(ActionType.View);
              setSidebarVisible(false);
              selectParameterId(undefined);
            }}
            action={action}
            setAction={setAction}
            refetch={refetch}
          />
        }
        isLoading={false}
      >
        <HeaderPanel titleText={parametersPage.title} showSearch onSearch={onSearch}>
          <Button label="Create" className="p-button-sm" icon={<FaPlusIcon />} onClick={addParameter} />
        </HeaderPanel>
        <div className="parameters-content">
          <ContextMenu model={menuModel} ref={cm} />
          <DeleteDialog
            message={
              <>
                Are you sure you want to delete parameter{' '}
                <span className="font-bold">
                  {parameters?.allParameters?.find((param) => param?.parameterId === selectedParameterId)?.name}
                </span>
                ?
                <br />
                This action can not be undone.
              </>
            }
            header="Delete Parameter"
            accept={() => tryToDeleteParameter(selectedParameterId!)}
            onHide={() => setDeleteDialogVisible(false)}
            visible={deleteDialogVisible}
          />
          <DataTable
            lazy
            responsiveLayout="stack"
            value={filteredParameters || []}
            loading={isLoading}
            contextMenuSelection={selectParameterId}
            onContextMenuSelectionChange={(e) => selectParameter(e.value.parameterId)}
            onContextMenu={(e) => cm && cm.current && cm.current.show(e.originalEvent)}
            onRowClick={(e) => selectParameter(e.data.parameterId)}
            onRowDoubleClick={(e) => {
              selectParameter(e.data.parameterId);
              setTimeout(editParameter, 300);
            }}
            rowClassName={rowClass}
          >
            <Column
              style={{ width: 240 }}
              field="name"
              header="Name"
              body={(data) => <ValueOrPlaceholder value={data.name} />}
            ></Column>
            <Column
              style={{ width: 250 }}
              field="queryStringName"
              header="Technical name"
              body={(data) => <ValueOrPlaceholder value={data.queryStringName} />}
            ></Column>
            <Column
              field="sampleValue"
              header="Sample data"
              body={(data) => <ValueOrPlaceholder value={data.sampleValue} />}
            ></Column>
            <Column
              style={{ width: 250 }}
              field="description"
              header="Description"
              body={(data) => <ValueOrPlaceholder value={data.description} />}
            ></Column>
            <Column
              style={{ width: 250 }}
              field="provider"
              header="Provider"
              body={(data) => <ValueOrPlaceholder value={sysCategoriesMap[data.sysCategory]} />}
            ></Column>
            <Column
              field="action"
              style={{ width: 50 }}
              body={(param) => (
                <Button
                  className="p-button-ellipsis"
                  icon={<FaEllipsisVIcon />}
                  onClick={(e) => {
                    selectParameter(param.parameterId);
                    cm.current.show(e);
                    e.stopPropagation();
                  }}
                />
              )}
            ></Column>
          </DataTable>
        </div>
      </MainLayoutWithSidebar>
    </Page>
  );
}

const parametersPage: IAppPage = {
  title: 'Parameters',
  icon: <FaBinaryIcon />,
  path: () => AppRoute.Parameters,
  page: <Parameters />,
  require: [],
};

export default parametersPage;
